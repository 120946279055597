import axios from "axios";
import { Queryparams } from "../Constants/Constants";
import moment from "moment";
import momentTz from 'moment-timezone'

export const UserLogin = async (uname, pword) => {
    return axios({
        url: Queryparams.url + "/user/login",
        method: "POST",
        data: {
            username: uname,
            password: pword
        },
        timeout: 10000,
    }).then(res => res)
        .catch(errmsg => errmsg);
}

export const FetchGames = async (token) => {
    return axios({
        url: Queryparams.url + "/games",
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        timeout: 10000,
    }).then(res => ({ err: false, data: res.data }
    )).catch(errmsg => ({ err: true, data: errmsg.response.data }));
}

export const FetchControls = async (gcode, url, token) => {
    return axios({
        url: url + "/control/" + gcode,
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        timeout: 10000,
    }).then(res => res)
        .catch(errmsg => errmsg);
}

export const UpdateControl = async (token, seldata, newamnt) => {
    return axios({
        url: Queryparams.url + "/control/set",
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        data: {
            gcode: seldata.gcode,
            combi: seldata.combi,
            ctrl_amt: parseFloat(newamnt)
        },
        timeout: 10000,
    }).then(res => res)
        .catch(errmsg => errmsg);
}

// GET USERS
export const FetchUsers = async (name, page, token, usertype) => {
    return axios({
        url: Queryparams.url + "/users/" + usertype + "?name=" + name + "&page=" + page + "&limit=20",
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        timeout: 10000,
    }).then(res => ({ err: false, data: res.data }
    )).catch(errmsg => ({ err: true, data: errmsg.response.data }));
}


// GET REPORTS
export const FetchReports = async (page, token, reptype, usertype, startdate, enddate, name) => {
    return axios({
        url: Queryparams.url + "/reports/summary/admin/" + reptype + "/" + usertype + "?startdate=" + startdate + "&enddate=" + enddate + "&name=" + name + "&limit=20&page=" + page,
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        timeout: 10000,
    }).then(res => ({ err: false, data: res.data }
    )).catch(errmsg => ({ err: true, data: errmsg.response.data }));
}

// GET REPORTS
export const FetchReportsDownline = async (page, token, reptype, usertype, startdate, enddate, name, upline) => {
    return axios({
        url: Queryparams.url + "/reports/summary/admin/" + reptype + "/" + usertype + "?startdate=" + startdate + "&enddate=" + enddate + "&name=" + name + "&limit=20&page=" + page + "&upline=" + upline,
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        timeout: 10000,
    }).then(res => ({ err: false, data: res.data }
    )).catch(errmsg => ({ err: true, data: errmsg.response.data }));
}

// GET REPORTS DOWNLOAD
export const FetchReportsDownlineDownload = async (page, token, reptype, usertype, startdate, enddate, name, upline) => {
    return axios({
        url: Queryparams.url + "/reports/summary/admin/" + reptype + "/" + usertype + "?startdate=" + startdate + "&enddate=" + enddate + "&name=" + name + "&limit=10000&page=" + page + "&upline=" + upline,
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        timeout: 10000,
    }).then(res => ({ err: false, data: res.data }
    )).catch(errmsg => ({ err: true, data: errmsg.response.data }));
}

export const FetchReportsall = async (token, reptype, usertype, startdate, enddate, name) => {
    return axios({
        url: Queryparams.url + "/reports/summary/admin/" + reptype + "/" + usertype + "?startdate=" + startdate + "&enddate=" + enddate + "&name=" + name + "&limit=10000",
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        timeout: 10000,
    }).then(res => ({ err: false, data: res.data }
    )).catch(errmsg => ({ err: true, data: errmsg.response.data }));
}
//GET COMBINATIONS BEFORE DRAW
export const FetchCombis = async (token, gcode) => {
    return axios({
        url: Queryparams.url + "/summary/bets/" + gcode,
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        timeout: 10000,
    }).then(res => ({ err: false, data: res.data }
    )).catch(errmsg => ({ err: true, data: errmsg.response.data }));
}

export const FetchRunning = async (token, gcode) => {
    return axios({
        url: Queryparams.url + "/running/" + gcode,
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        timeout: 10000,
    }).then(res => ({ err: false, data: res.data }
    )).catch(errmsg => ({ err: true, data: errmsg.response.data }));
}

// ADD ADMIN
export const AddAdmin = async (token, f_name, l_name, username, password, mobile_num) => {
    return axios({
        url: Queryparams.url + "/user/register/admin",
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        data: {
            f_name: f_name,
            l_name: l_name,
            username: username,
            password: password,
            mobile_num: mobile_num,
        },
        timeout: 10000,
    }).then(res => ({ err: false, data: res.data }
    )).catch(errmsg => ({ err: true, data: errmsg.response.data }));
}


export const FetchEntries = async (gcode, searchdate, searchtcode, searchname, page, url, token) => {
    return axios({
        url: url + "/history/raw?drawdate=" + searchdate + "&game=" + gcode + "&limit=50&page=" + page + "&transcode=" + searchtcode + "&name=" + searchname,
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        timeout: 10000,
    }).then(res => res)
        .catch(errmsg => errmsg);
}

export const FetchHits = async (gcode, searchdate, page, url, token) => {
    return axios({
        url: url + "/reports/hits/details?drawdate=" + searchdate + "&game=" + gcode + "&limit=20&page=" + page,
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        timeout: 10000,
    }).then(res => res)
        .catch(errmsg => errmsg);
}

// HELPER FUNCTIONS
export function Converter(inString) {
    if (inString !== '') {
        return "₱ " + commify(parseFloat(inString).toFixed(2));
    }
}

export const commify = (n) => {
    var parts = n.toString().split(".");
    const numberPart = parts[0];
    const decimalPart = parts[1];
    const thousands = /\B(?=(\d{3})+(?!\d))/g;
    return numberPart.replace(thousands, ",") + (decimalPart ? "." + decimalPart : "");
}

export const parseDate = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

export function GetLongDate() {
    var dateIn = moment(new Date());
    return dateIn.tz('Asia/Manila').format('MMMM Do YYYY, h:mm:ss a')
}