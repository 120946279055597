import { Box, Table, TableContainer, Tbody, Td, Th, Thead, Tr, Text, Tooltip } from '@chakra-ui/react'
import React from 'react'
import { commify } from '../Queries/Queries'
import { clrs } from '../Constants/Constants'

const TablerEntries = ({ entries }) => {
    return (
        <TableContainer height={'70vh'} overflowY={'scroll'}
            sx={{
                '&::-webkit-scrollbar': {
                    height: '1px',
                    width: '5px',
                    borderRadius: '2px',
                    backgroundColor: `rgba(0, 0, 0, 0.05)`,
                },
                '&::-webkit-scrollbar-thumb': {
                    // backgroundColor: `rgba(0, 0, 0, 0.05)`,
                    backgroundColor: clrs.lable,
                },
            }}
        >
            <Table size='sm' color={'white'} >
                <Thead >
                    <Tr borderColor={clrs.lable} borderWidth={2} >
                        <Th color={'white'} py={3} ><Text wordBreak={"normal"}>TCode/Draw</Text></Th>
                        <Th color={'white'} py={3} ><Text>Cr8/Upd8</Text></Th>
                        <Th color={'white'} py={3} ><Text>Sender</Text></Th>
                        <Th color={'white'} py={3} ><Text>Payload</Text></Th>
                        <Th color={'white'} py={3} ><Text>Total</Text></Th>
                        <Th color={'white'} py={3} ><Text>Void</Text></Th>

                    </Tr>
                </Thead>
                <Tbody>
                    {
                        entries.length > 0 ? entries.map((ct, idx) => (
                            <Tr key={idx} borderColor={clrs.lable} borderWidth={'2px'} bg={ct.void ? "gray" : "transparent"}>
                                <Td w={10} ><Text textAlign={'right'}  >{ct.aptr.length === 0 ? ct.tcode : ct.aptr} <br /> {ct.drwdate + "  " + ct.gperiod}</Text></Td>
                                <Td w={10} style={{ width: '10px' }} textAlign={'center'}><Text></Text>{ct.c_at} <br />{ct.u_at}</Td>
                                <Td w={20}><Text whiteSpace="normal" height="auto" blockSize="auto" width={20}>{ct.name}</Text></Td>
                                <Td >
                                    <Tooltip label={
                                        ct.rawresp && ct.rawresp.map((val) => (
                                            <Box>
                                                <Text key={idx}>{val.com + "\tT" + val.bet_t + "R" + val.bet_r + " " + val.not}</Text>
                                                {
                                                    val.rso ? val.rso.map((nt, idx) => (
                                                        <Text style={{ paddingLeft: 20 }} key={idx}>{"- SO " + nt}</Text>
                                                    )) : null
                                                }
                                            </Box>
                                        ))

                                    } aria-label='A tooltip'>
                                        <Box whiteSpace="normal" height="auto" blockSize="auto" width={210}>
                                            <Text whiteSpace="normal" height="auto" blockSize="auto" width={40}>{ct.payload}</Text>
                                        </Box>
                                    </Tooltip>
                                </Td>
                                <Td w={20}><Text textAlign={'right'} color={ct.void ? 'white' : "lime"}>{"₱ " + commify(parseFloat(ct.tot))}</Text></Td>
                                <Td><Text style={{ backgroundColor: 'red', padding: 5, borderRadius: 5, backgroundColor: ct.void ? 'red' : 'blue', width: '30px', textAlign: 'center' }}>{ct.void ? "V" : "N"}</Text></Td>
                            </Tr>
                        )) :
                            <Tr borderColor={clrs.lable} borderWidth={'2px'}>
                                <Td color={'whiteAlpha.500'} textAlign={'center'} colSpan={3}>No entries Found.</Td>
                            </Tr>
                    }
                </Tbody>
            </Table>
        </TableContainer>
    )
}

export default TablerEntries